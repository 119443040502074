/* General Styling */
.no-padding-container {
  padding: 0;
}

.fullwidth-slider-container {
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden;

}

/* For large desktops */
@media (max-width: 1400px) {
  .fullwidth-slider-container {
    width: 100%;
    height: 100vh; /* Full viewport height */
    margin-bottom: -20px; /* Increase margin by 20px */
    margin-bottom: 10px; /* Increase margin by 20px */
  }
}

/* For desktops */
@media (max-width: 1200px) {
  .fullwidth-slider-container {
    width: 100%;
    height: 100vh; /* Full viewport height */
    margin-bottom: 0px; /* Increase margin by 20px */
  }
}

/* For medium desktops */
@media (max-width: 992px) {
  
  .fullwidth-slider-container {
    width: 100%;
    height: 100vh; /* Full viewport height */
    margin-bottom: -10px; /* Increase margin by 20px */
  }
}

/* For large tablets */
@media (max-width: 768px) {
  .fullwidth-slider-container {
    width: 100%;
    height: 100vh; /* Full viewport height */
    margin-bottom: -15px; /* Increase margin by 20px */
  }
}

/* For medium tablets */
@media (max-width: 600px) {
  .fullwidth-slider-container {
    width: 100%;
    height: 100vh; /* Full viewport height */
    margin-bottom: -20px; /* Increase margin by 20px */
  }
}

/* For small tablets */
@media (max-width: 480px) {
  .fullwidth-slider-container {
    width: 100%;
    height: 100vh; /* Full viewport height */
    margin-bottom: -80px; /* Increase margin by 20px */
  }
}

/* For large mobile devices */
@media (max-width: 400px) {
  .fullwidth-slider-container {
    margin-bottom: -240px; /* Increase margin by 20px */
  }
}

/* For small mobile devices */
@media (max-width: 320px) {
  .fullwidth-slider-container {
    margin-bottom: -180px; /* Increase margin by 20px */
  }
}

/* For very small mobile devices */
@media (max-width: 280px) {
  .fullwidth-slider-container {
    margin-bottom: -200px; /* Increase margin by 20px */
  }
}

/* Pumping Button Animation */
.pumping-button {
  margin: 0 15px; /* Space between buttons */
  padding: 10px 20px;
  background-color: yellow;
  color: black;
  border: none;
  font-size: 18px;
  cursor: pointer;
  transition: transform 0.2s, background-color 0.2s;
  animation: pump 1s infinite alternate;
}

.pumping-button:hover {
  background-color: purple;
  color: white;
}

/* Animation Keyframes */
@keyframes pump {
  0% {
    transform: scale(1);
    background-color: yellow;
  }
  100% {
    transform: scale(1.1); /* Increase size */
    background-color: purple; /* Change color */
  }
}
