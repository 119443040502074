.signup-container {
    background-color: black;
    color: purple; /* Changed font color to purple */
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .signup-form {
    display: flex;
    flex-direction: column;
    width: 300px;
    padding: 20px;
    border: 1px solid #fff;
    border-radius: 10px;
  }
  
  .signup-form input {
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    border: none;
  }
  
  .signup-form label {
    margin-bottom: 5px;
  }
  
  .submit-button {
    background-color: yellow;
    color: black;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: blue;
    color: white;
  }
  