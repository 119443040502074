.text-purple {
  color: purple;
}


/* Default styles */
.button-container {
margin-bottom: -30px;
}

/* For small screens (mobile devices) */
@media (max-width: 768px) {
.button-container {
  margin-bottom: -60px; /* Move up more */
}

/* Optional: Adjust button size or padding for mobile screens */
.pumping-button {
  padding: 0.5rem 1rem; /* Adjust padding if needed */
}
}
