.fullwidth-slider-container {
  width: 100%;
  height: 100vh; /* Full viewport height */
  overflow: hidden;

}

/* For large desktops */
@media (max-width: 1400px) {
  .fullwidth-slider-container {
    width: 100%;
    height: 100vh; /* Full viewport height */
    margin-bottom: -20px; /* Increase margin by 20px */
    margin-bottom: 10px; /* Increase margin by 20px */
  }
}

/* For desktops */
@media (max-width: 1200px) {
  .fullwidth-slider-container {
    width: 100%;
    height: 100vh; /* Full viewport height */
    margin-bottom: 0px; /* Increase margin by 20px */
  }
}

/* For medium desktops */
@media (max-width: 992px) {
  
  .fullwidth-slider-container {
    width: 100%;
    height: 100vh; /* Full viewport height */
    margin-bottom: -10px; /* Increase margin by 20px */
  }
}

/* For large tablets */
@media (max-width: 768px) {
  .fullwidth-slider-container {
    width: 100%;
    height: 100vh; /* Full viewport height */
    margin-bottom: -15px; /* Increase margin by 20px */
  }
}

/* For medium tablets */
@media (max-width: 600px) {
  .fullwidth-slider-container {
    width: 100%;
    height: 100vh; /* Full viewport height */
    margin-bottom: -20px; /* Increase margin by 20px */
  }
}

/* For small tablets */
@media (max-width: 480px) {
  .fullwidth-slider-container {
    width: 100%;
    height: 100vh; /* Full viewport height */
    margin-bottom: -80px; /* Increase margin by 20px */
  }
}

/* For large mobile devices */
@media (max-width: 400px) {
  .fullwidth-slider-container {
    margin-bottom: -100px; /* Increase margin by 20px */
  }
}

/* For small mobile devices */
@media (max-width: 320px) {
  .fullwidth-slider-container {
    margin-bottom: -180px; /* Increase margin by 20px */
  }
}

/* For very small mobile devices */
@media (max-width: 280px) {
  .fullwidth-slider-container {
    margin-bottom: -200px; /* Increase margin by 20px */
  }
}


  .fullwidth-image {
    width: 100%;
    height: 100%; /* Ensures the image covers the entire height */
    object-fit: cover; /* Maintains aspect ratio and covers the area */
  }
  
  .yellow-button {
    background-color: yellow;
    color: black;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    animation: pump 1s infinite ease-in-out;
  }
  
  @keyframes pump {
    0%, 100% {
      transform: scale(1); /* Normal size */
      background-color: yellow; /* Start with yellow */
    }
    50% {
      transform: scale(1.2); /* Grow in size */
      background-color: purple; /* Change to purple */
    }
  }
  
  
  .yellow-button:hover {
    background-color: purple;
    color: white;
  }
  
  .box-btn {
    margin-top: 10px;
    display: block;
    width: 100%;
  }
  

  .text-purple {
    color: purple;
  }
  