@keyframes pump {
  0%, 100% {
    transform: scale(1);
    background-color: #ffcc00; /* Yellow */
  }
  50% {
    transform: scale(1.2); /* Larger size on pump */
    background-color: #6a0dad; /* Purple */
  }
}

.main-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000; /* Solid black background */
  color: #fff;
  padding: 20px;
}

.btn-custom {
  background-color: #ffcc00; /* Initial yellow color */
  color: #000;
  font-size: 1.5rem; /* Larger font for better readability */
  padding: 100px; /* Increased padding for circle shape */
  width: 150px;
  height: 150px;
  border: none;
  border-radius: 50%; /* Circle shape */
  transition: all 0.3s ease;
  animation: pump 1.5s infinite; /* Pumping animation */
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 80px ; /* Auto margin for horizontal centering */
  box-shadow: 0px 4px 8px rgba(255, 204, 0, 0.4);
}

@media (max-width: 768px) {
  .btn-custom {
    font-size: 1.2rem;
    padding: 70px;
    width: 130px;
    height: 130px;
  }
}

@media (max-width: 576px) {
  .btn-custom {
    font-size: 1.1rem;
    padding: 60px;
    width: 120px;
    height: 120px;
    margin: 20px auto; /* Ensure the buttons are centered */
  }
}

@media (max-width: 375px) {
  .btn-custom {
    font-size: 1rem; /* Adjusted font size for smaller screens */
    padding: 50px;
    width: 110px;
    height: 110px;
    margin: 20px auto; /* Ensure horizontal centering */
  }
}
