.apartment-card {
  border: 2px solid purple; /* Purple border around the entire card */
  border-radius: 10px;
  padding: 10px;
  position: relative;
  transition: transform 0.3s ease; /* Smooth scaling on hover */
  background-color: purple; /* Set background color to purple */
  color: purple; /* Ensure the text color contrasts with the purple background */
}

.apartment-card:hover {
  transform: scale(1.05); /* Slight zoom-in effect on hover */
}

.phone-number {
  background-color: purple; /* Purple background for phone number */
  color: white; /* White text color */
  padding: 10px;
  border-radius: 0 0 10px 10px; /* Rounded corners */
  text-align: center;
  margin-top: 10px;
  font-size: 1.2rem; /* Increase font size */
  position: relative;
}

.button-custom {
  /* Add any shared button styles here */
  width: 100%;
}


@media (max-width: 576px) {
  .button-custom {
    font-size: 1rem;
    padding: 20px;
    width: 90px;
    height: 90px;
    margin-left: 100px
  }
} 

.mt-2 {
  margin-top: 0.5rem;
}

.p-2 {
  padding: 0.75rem;
}
