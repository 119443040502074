.upload-profile-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(135deg, black, #111);
  color: white;
  padding: 40px;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  margin: 50px auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.upload-title {
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: yellow;
  text-align: center;
}

.upload-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.file-input {
  margin: 20px 0;
  padding: 10px;
  width: 100%;
  color: yellow;
  cursor: pointer;
  border: 2px dashed yellow;
  border-radius: 8px;
  background: none;
  transition: border-color 0.3s ease;
}

.file-input:hover {
  border-color: purple;
}

.upload-button {
  background-color: yellow;
  color: black;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  margin-top: 10px;
  box-shadow: 0 4px 8px rgba(255, 255, 0, 0.3);
}

.upload-button:hover {
  background-color: purple;
  color: white;
}

.next-button {
  background-color: yellow;
  color: black;
  margin-top: 30px;
  padding: 15px 60px;
  font-size: 1.2rem;
  cursor: pointer;
  border: none;
  border-radius: 8px;
  transition: background-color 0.3s ease, color 0.3s ease;
  box-shadow: 0 4px 8px rgba(255, 255, 0, 0.3);
}

.next-button:hover {
  background-color: purple;
  color: white;
}

.sign-in-link {
  margin-top: 20px;
}

.sign-in-link a {
  color: yellow;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.sign-in-link a:hover {
  color: purple;
}
